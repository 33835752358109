import config from "config/main-config.json";
import { useEffect } from "react";
import { mapDualStoreScenarios } from "helpers/Scenarios.helpers";
import PropTypes from "prop-types";
import { lazy } from "react";
import { twMerge } from "tailwind-merge";
import AmsterdamModalButton from "components/BookingButton/AmsterdamModalButton";

const Enterprises = lazy(() =>
    import("components/Homepage/Enterprises/Enterprises")
);
const LyonAboutus = lazy(() => import("components/Homepage/About/LyonAboutus"));
const RomeCubeSection = lazy(() =>
    import("components/Homepage/RomeCubeSection/RomeCubeSection")
);
const CustomHomeSection = lazy(() =>
    import("components/Homepage/CustomHomeSection/CustomHomeSection")
);
const Slicker = lazy(() => import("components/Slicker/Slicker"));
const Popup = lazy(() => import("components/Popup/Popup"));
const PopupNotice = lazy(() => import("components/Popup/PopupNotice"));
const HomeCarousel = lazy(() => import("components/HomeCarousel/HomeCarousel"));
const Scenarios = lazy(() => import("components/Homepage/Scenarios/Scenarios"));
const Map = lazy(() => import("components/Homepage/Map/Map"));
const Counter = lazy(() => import("components/Homepage/Counter/Counter"));
const KidSection = lazy(() =>
    import("components/Homepage/KidSection/KidSection")
);
const About = lazy(() => import("components/Homepage/About/About"));
const ContactButtons = lazy(() =>
    import("components/ContactButtons/ContactButtons.jsx")
);
const BookingButton = lazy(() =>
    import("components/BookingButton/BookingButton.jsx")
);
const SideBanners = lazy(() => import("components/SideBanners/SideBanners"));
const LondonZappierForm = lazy(() =>
    import("components/LondonZappierForm/LondonZappierForm")
);
const LivePlay = lazy(() => import("components/Homepage/LivePlay/LivePlay"));

export const Home = ({ shouldShow }) => {
    const { header, titles } = config.runtime.pages.components.about_us;
    const hasPopup = config.popupImg ? true : false;
    const hasPopupNotice = config.popupNotice ?? false;
    const lyonAboutUs = config.lyonAboutus ? true : false;
    const kidSection = config.kidSection ?? false;
    const hasContactButtons = config.hasContactButtons ? true : false;
    const fixedBookingButton = config.fixedBookingButton ? true : false;
    const { fareharborModal } = config;
    const hideAboutUsSection = config.hideAboutUsSection;
    const hideCounterSection = config.hideCounterSection;
    const hasLondonZipperForm = config.hasLondonZipperForm;
    const livePlaySection = config.livePlaySection;
    const romeCubeSection = config.romeCubeSection ?? false;
    const { customHomeSection } = config;

    useEffect(() => {
        if (fareharborModal) {
            const script = document.createElement("script");
            script.src =
                "https://fareharbor.com/embeds/api/v1/?autolightframe=yes";
            document.body.appendChild(script);
        }

        return () => {
            const script = document.querySelector(
                'script[src="https://fareharbor.com/embeds/api/v1/?autolightframe=yes"]'
            );
            if (script) {
                script.remove();
            }
        };
    }, [fareharborModal]);
    const home_enterprises = config.runtime.pages.components
        .homepage_enterprises
        ? true
        : false;

    return (
        <>
            {shouldShow && hasPopup && <Popup />}
            {shouldShow && hasPopupNotice && <PopupNotice />}
            {config.nearbyStores && <Slicker />}
            {config.runtime.pages.home.SideBanners && <SideBanners />}
            <h1 className='-z-10 text-[0.1px] absolute text-transparent'>
                {config.stores[0].location.city} GAME OVER Escape Rooms{" "}
                {config.stores[0].location.country}
            </h1>
            <HomeCarousel />
            {shouldShow && (
                <>
                    <Scenarios
                        data={mapDualStoreScenarios()}
                        page='home'
                    />

                    {romeCubeSection && <RomeCubeSection />}
                    {kidSection && <KidSection />}
                    {livePlaySection && <LivePlay />}
                    <Map />
                    {!hideCounterSection && <Counter />}
                    {lyonAboutUs && <LyonAboutus />}
                    {!hideAboutUsSection && (
                        <About
                            header={header}
                            titles={titles}
                            linkId={twMerge(
                                "uppercase font-Raleway text-[1rem] font-normal inline-block",
                                " leading-[1.5] text-primary-red border-box py-[0.375rem]",
                                "px-[0.75rem] border-[1px] border-primary-red border-solid",
                                "hover:bg-primary-red hover:text-black"
                            )}
                            className='text-[1.8rem] md:text-[1.7rem]'
                            ImageId='book_now_imgr'
                        />
                    )}
                    {home_enterprises && <Enterprises />}
                    {hasLondonZipperForm && <LondonZappierForm />}
                </>
            )}
            {hasContactButtons && <ContactButtons />}
            {fixedBookingButton && <BookingButton />}
            {fareharborModal && (
                <>
                    <AmsterdamModalButton />
                </>
            )}
            {customHomeSection && <CustomHomeSection />}
        </>
    );
};

Home.propTypes = {
    shouldShow: PropTypes.bool,
};

export default Home;
