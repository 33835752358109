export const metaMap = {
    atlanta: {
        title: "Escape Room Atlanta, GA The Best Thrilling Adventure",
        description:
            "Join the ultimate escape room experience in Atlanta, GA. Explore themes like Horror Circus, Lost City of Atlantis, and more. Perfect for team-building and family fun!",
    },
    orlando: {
        title: "Escape Room Orlando, FL Ultimate Puzzle Adventure",
        description:
            "Escape Room Orlando, FL offers thrilling themed rooms with challenging puzzles. Book now for an unforgettable adventure with friends.",
    },
    default: {
        getTitle: (config) =>
            `${config.stores[0].location.city} - GAME OVER Escape Rooms - ${config.stores[0].location.country}`,
        getDescription: (config) =>
            `GAME OVER Escape Rooms ${config.stores[0].location.city}. Experience the unique atmosphere of our escape games and organize fascinating events at our facilities!`,
    },
};
