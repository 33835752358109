import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const IconContainer = ({ IconImg, alt, paragraph, optionalClass }) => {
    return (
        <div
            className='col mx-auto flex flex-col items-center text-center md:odd:[&:last-of-type]:ml-[50%]'
            id='category2'>
            <img
                loading='lazy'
                className='w-[48px] h-[48px]'
                src={IconImg}
                alt={alt}
            />
            <div className='text-center'>
                <p
                    id='category5'
                    className={twMerge(
                        "[&>span]:!text-primary-red !text-primary-red",
                        "[&>span]:!text-[1.9rem] text-[1.9rem] mdl:text-[22px] [&>span]:!font-normal",
                        "[&>span]:font-[Raleway] mdl:[&>span]:!text-[22px] !flex text-capitalize",
                        `${optionalClass}`
                    )}>
                    {paragraph}
                </p>
            </div>
        </div>
    );
};

IconContainer.propTypes = {
    IconImg: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    paragraph: PropTypes.node.isRequired,
    optionalClass: PropTypes.string,
};

export default IconContainer;
